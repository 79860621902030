import React from "react";

import { Heading, HeadingLevel } from "baseui/heading";
import { Paragraph1, Label1 } from "baseui/typography";
import { StyledLink } from "baseui/link";

import Page from "../../components/internal-page";

const Steps = () => {
  return (
    <Page
      title="О региональном Фонде развития промышленности"
      path="/frp/about"
      parent="frp"
    >
      <Paragraph1>
        Фонд "МКК Развитие" осуществляет функции и полномочия регионального
        Фонда развития промышленности в Архангельской области
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1ZVrkGd_i63CvXZWXpKEKcA5rjJbVoADs/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Распоряжение правительства Архангельской области о создании ФРП
        </StyledLink>
      </Paragraph1>
      <Paragraph1>
        <StyledLink
          href="https://drive.google.com/file/d/1d3U2r_fD281LFWBoa--goyrVnCxCL9j9/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Соглашение с федеральным Фондом Развития Промышленности
        </StyledLink>
      </Paragraph1>
      <HeadingLevel>
        <Heading>Экспертный совет</Heading>
      </HeadingLevel>
      <Label1>Председатель Экспертного совета</Label1>
      <Paragraph1>
        Есеев Марат Каналбекович, проректор по инновационному развитию - научный
        руководитель НОЦ мирового уровня "Российская Арктика" Северного
        (Арктического) федерального университета имени М.В. Ломоносова,
        д.ф.-м.н., профессор
      </Paragraph1>
      <Label1>Заместитель председателя Экспертного совета</Label1>
      <Paragraph1>
        Чижова Людмила Александровна, ведущий научный сотрудник Федерального
        исследовательского центра комплексного изучения Арктики имени академика
        Н.П. Лаверова Уральского отделения Российской академии наук, к.э.н.,
        доцент
      </Paragraph1>
      <Label1>Секретарь Экспертного совета</Label1>
      <Paragraph1>
        Иванова Полина Сергеевна, финансовый аналитик отдела по работе с
        заёмщиками Фонда "МКК Развитие"
      </Paragraph1>
      <HeadingLevel>
        <Heading>Контакты по вопросам деятельности ФРП</Heading>
      </HeadingLevel>
      <Label1>
        В Министерстве экономического развития, промышленности и науки
        Архангельской области
      </Label1>
      <Paragraph1>
        Дудникова Юлия Михайловна – заместитель начальника управления
        промышленности, тел.&nbsp;(8182)&nbsp;288535, электронная почта:
        dudnikova.ym@dvinaland.ru
      </Paragraph1>
      <Label1>В Фонде "МКК Развитие"</Label1>
      <Paragraph1>
        Иванова Полина Сергеевна, тел.&nbsp;(8182)&nbsp;464122&nbsp;доб.&nbsp;107,
        +7&nbsp;(921)&nbsp;499-9417, электронная почта: frp@cmf29.ru
      </Paragraph1>
    </Page>
  );
};

export default Steps;
